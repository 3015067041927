import React from 'react';
import { Layout } from 'src/components/Layout';
import { SignUpPage } from 'src/components/SignUpPage/component';

export default class SignUpRequired extends React.Component {
	render() {
		return (
			<Layout suppressVerificationReminder={true}>
				<SignUpPage
					controller={this}
					headingText="Please create an account to access more puzzles"
				/>
			</Layout>
		);
	}
}
